import { axiosInstance } from "./base";
import { headers } from "./base";

export class FmsService {
  COS_BASE_URL = process.env.REACT_APP_COS_BASE_URL;

  getDriverList = (phoneNumber='', name='', licenseNumber='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/drivers?name=${name}&phoneNumber=${phoneNumber}&licenseNumber=${licenseNumber}`, {headers: headers()});
  };

  getPermissionList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/user-role`, {headers: headers()});
  };

  getVehicleList = (vehicleNumber='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicle-entries?vehicleNumber=${vehicleNumber}`, {headers: headers()});
  };


  getIssueTypeList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issue-categories`, {headers: headers()});
  };

  getIssueCategoryByIssueTypeCodeList = (code) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issue-subcategories/code/${code}`, {headers: headers()});
  };

  getAllIssueListByOrg = (code) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issues-org/${code}`, {headers: headers()});
  };

  createIssue = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/issues`, payload,{headers: headers()});
  };

  getIssuesbyOrgList = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/issues-org/${payload}`, {headers: headers()});
  };

  createVehicle = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/vehicle-entries`, payload,{headers: headers()});
  };

  
  getVehicleModelList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicle-model`, {headers: headers()});
  };

  createDriver = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/drivers`, payload, {headers: headers()});
  };

  getDriverListByOrg = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/drivers/org/${payload}`, {headers: headers()});
  };


  // Order

  getOrderList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/order`, {headers: headers()});
  }

  getOrderDetails = (id) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/order/${id}`, {headers: headers()});
  }

  createNewOrder = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/order`, payload, {headers: headers()});
  }

  createNewCustomer = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/fms/customer`, payload, {headers: headers()});
  }

  getCustomerList = (search='') => {
    return axiosInstance.get(`${this.COS_BASE_URL}/fms/customer?search=${search}`, {headers: headers()});
  }

} 

