import Two from '../assets/gif/motorcycle.gif';
import EAuto from '../assets/gif/electric-scooter.gif';
import ERickshaw from '../assets/gif/mini-mobility.gif';
import ThreeWheelerCargo from '../assets/gif/cargo-bike.gif';
import FourWheelerPassenger from '../assets/gif/sedan.gif';
import FourWheelerCargo from '../assets/gif/pickup-truck.gif';
import Truck from '../assets/gif/long-truck.gif';
import Bus from '../assets/gif/bus.gif';
import defaultLogo from '../assets/gif/magnifying-glass.gif';
import Term from '../assets/gif/alms.gif';
import Working from '../assets/gif/money-bag.gif';
import ApplicationComplete from '../assets/gif/good-job.gif';
import SupportCall from '../assets/gif/customer-support.gif';
import Discussion from '../assets/gif/discussion.gif';
import ExtraDoc from '../assets/gif/search.gif';
import DisbursedPic from '../assets/gif/success (1).gif';
import FleetSoftware from '../assets/gif/fleet-software.gif';
 
export const UserRole = {
  Admin: 0,
  Editor: 1,
};

export const defaultMenuType = 'menu-default';
export const serverEnv = process?.env?.REACT_APP_SERVER;
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const completedSteps = ['WAITING', 'COMPLETE'];
export const directorKycStatus = ['PENDING', 'VERIFIED'];
export const applicationStatus = ['COMPLETE', 'UNDER_REVIEW', 'ON_HOLD'];
export const stepSequence = ['basic-info', 'requirements', 'business-info', 'business-kyc', 'kyc', 'upload-doc', 'application-summary', 'agreement'];

export const processSequence = [
  // { 
  //   title: 'Complete application',
  //   img: ApplicationComplete
  // }, 
  {
    title: 'Review',
    img: SupportCall,
    details: 'We’ll check your details and get back to you within 24 hours.'
  }, 
  {
    title: 'Verification',
    img: ExtraDoc,
    details: 'We might ask for more info if needed'
  }, 
  {
    title: 'Personal Discussion (PD)',
    img: Discussion,
    details: 'You will receive a call from our credit officer to understand your requirement in more detail and have a quick QnA.'
  }, 
  {
    title: 'Status',
    img: DisbursedPic,
    details: 'we will keep you updated on the status of your application via email/SMS/Whatsapp'
  }
];

export const stepDisplayName = {
  'basic-info': 'Basic Information',
  'requirements': 'Requirements',
  'business-kyc': 'Business KYC',
  'business-info': 'Business Information',
  'kyc': 'KYC',
  'upload-doc': 'Additional Information',
  'application-summary': 'Application Summary'
}

export const status = {
  'PENDING': 'danger',
  'INPROGRESS': 'warning',
  'COMPLETE': 'success'
}

export const productImage = {
  'Two Wheeler': Two,
  'E-Auto': EAuto,
  'E-Rickshaw': ERickshaw,
  'Three Wheeler (Cargo)': ThreeWheelerCargo,
  'Four Wheeler (Passenger)': FourWheelerPassenger,
  'Four Wheeler (Cargo)': FourWheelerCargo,
  'E-Truck': Truck,
  'E-Bus': Bus,
  'Term Loan': Term,
  'Working Capital': Working,
  'Fleet Management Software': FleetSoftware,
  'default': defaultLogo
}

export const departmentList = [
  {
    label: 'Founder',
    value: 'Founder'
  },
  {
    label: 'Founder`s office',
    value: 'Founder`s office'
  },
  {
    label: 'Financial accounting',
    value: 'Financial accounting'
  },
  {
    label: 'Human Resources',
    value: 'Human Resources'
  },
  {
    label: 'Marketing',
    value: 'Marketing'
  },
  {
    label: 'Information technology',
    value: 'Information technology'
  },
  {
    label: 'Marketing management',
    value: 'Marketing management'
  },
  {
    label: 'Sales',
    value: 'Sales'
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const PartnerList = [
  {
    label: 'Zomato',
    value: 'Zomato'
  },
  {
    label: 'Swiggy',
    value: 'Swiggy'
  },
  {
    label: 'Blinkit',
    value: 'Blinkit'
  },
  {
    label: 'Zepto',
    value: 'Zepto'
  },
  {
    label: 'Porter',
    value: 'Porter'
  },
  {
    label: 'Others',
    value: 'Others'
  }
]

export const entityType = [
  {
    label: 'Public Limited',
    value: 'Public Limited'
  },
  {
    label: 'Private Limited',
    value: 'Private Limited'
  }, 
  {
    label: 'Sole proprietorship',
    value: 'Sole proprietorship'
  }, 
  {
    label: 'Limited Liability Partnerships (LLP)',
    value: 'Limited Liability Partnerships (LLP)'
  }, 
  {
    label: 'Individual',
    value: 'Individual'
  }
]

export const typeOfBusiness = [
  {
    label: 'Fleet-Passenger Mobility',
    value: 'Fleet-Passenger Mobility'
  }, 
  {
    label: 'Fleet-Goods Mobility',
    value: 'Fleet-Goods Mobility'
  }, 
  {
    label: 'CPO/CNO',
    value: 'CPO/CNO'
  }, 
  {
    label: 'Battery Swapping',
    value: 'Battery Swapping'
  }, 
  {
    label: 'Battery Manufacturing',
    value: 'Battery Manufacturing'
  }, 
  {
    label: 'Other Battery Related',
    value: 'Other Battery Related'
  }, 
  {
    label: 'Vehicle Manufacturer',
    value: 'Vehicle Manufacturer'
  }, 
  {
    label: 'Dealer',
    value: 'Dealer'
  }, 
  {
    label: 'Others',
    value: 'Others'
  }
]

export const topPartnerLabelMapping = {
  'Fleet-Passenger Mobility': 'Top 3 customers',
  'Fleet-Goods Mobility': 'Top 3 customers',
  'CPO/CNO': 'Top fleet operators (customers)',
  'Battery Swapping': 'Top fleet operators (customers)',
  'Battery Manufacturing': 'Top 3 suppliers',
  'Other Battery Related': 'Top 3 suppliers',
  'Vehicle Manufacturer': 'Top 3 suppliers',
  'Dealer': 'Top 3 suppliers',
  'Others': 'Top 3 customers'
}

export const businessCategory = [
  {
    label: 'Two wheeler',
    value: 'Two wheeler'
  }, 
  {
    label: 'E-Rickshaw',
    value: 'E-Rickshaw'
  }, 
  {
    label: 'E-Auto',
    value: 'E-Auto'
  }, 
  {
    label: 'Three wheelar cargo',
    value: 'Three wheelar cargo'
  }, 
  {
    label: 'Four wheeler passenger',
    value: 'Four wheeler passenger'
  }, 
  {
    label: 'Four wheeler cargo',
    value: 'Four wheeler cargo'
  }, 
  {
    label: 'E-Truck',
    value: 'E-Truck'
  }, 
  {
    label: 'E-Bus',
    value: 'E-Bus'
  },
  {
    label: 'Others',
    value: 'Others'
  }
]

export const insuranceProductType = [
  {
    label: 'Bike',
    value: 'Bike'
  }, 
  {
    label: 'Scooter',
    value: 'Scooter'
  }, 
  {
    label: 'Pvt Car',
    value: 'Pvt Car'
  }, 
  {
    label: '3W E-Cart',
    value: '3W E-Cart'
  }, 
  {
    label: '3W E-Rikshaw',
    value: '3W E-Rikshaw'
  }, 
  {
    label: '3W Auto',
    value: '3W Auto'
  }, 
  {
    label: 'Taxi',
    value: 'Taxi'
  }, 
  {
    label: 'Upto 2.5T',
    value: 'Upto 2.5T'
  }, 
  {
    label: '2.5T-3.5T',
    value: '2.5T-3.5T'
  }, 
  {
    label: '3.5T - 7.5T',
    value: '3.5T - 7.5T'
  }, 
  {
    label: '7.5T - 12T',
    value: '7.5T - 12T'
  }, 
  {
    label: '12T - 20T',
    value: '12T - 20T'
  }, 
  {
    label: '20T - 40T',
    value: '20T - 40T'
  },
  {
    label: 'Tractor',
    value: 'Tractor'
  }, 
  {
    label: 'Route Bus',
    value: 'Route Bus'
  }, 
  {
    label: 'School Bus',
    value: 'School Bus'
  }, 
  {
    label: 'CPM',
    value: 'CPM'
  }
]

export const vehicleFuelType = [
  {
    label: 'Petrol',
    value: 'Petrol'
  }, 
  {
    label: 'Diesel',
    value: 'Diesel'
  },
  {
    label: 'CNG',
    value: 'CNG'
  }, 
  {
    label: 'Electric',
    value: 'Electric'
  }, 
  {
    label: 'Hybrid',
    value: 'Hybrid'
  },
]

export const vehicleInsurancePolicyType = [
  {
    label: 'Bundled (1OD + 5TP)',
    value: 'Bundled (1OD + 5TP)'
  }, 
  {
    label: 'Bundled (1OD + 3TP) Only For Pvt Car',
    value: 'Bundled (1OD + 3TP) Only For Pvt Car'
  }, 
  {
    label: 'COMP (1OD + 1TP)',
    value: 'COMP (1OD + 1TP)'
  },
  {
    label: 'SAOD (1OD)',
    value: 'SAOD (1OD)'
  }, 
  {
    label: 'SATP (1TP)',
    value: 'SATP (1TP)'
  },
];

export const dashboardConsolidatedVehicleDataApiKeys = [
  'overallTrackingStatus',
  'totalOdometer',
  'totalEmission',
  'overallBatteryPercentageDistribution',
  'temperatureDistribution',
  'vehicleStatus',
  'dailyEmission', 
  'dailyOdometer'
]

export const defaultCityList = [
  {
    label: 'Mumbai',
    value: 'Mumbai'
  },
  {
    label: 'Delhi',
    value: 'Delhi'
  },
  {
    label: 'Bengaluru',
    value: 'Bengaluru'
  },
  {
    label: 'Hyderabad',
    value: 'Hyderabad'
  },
  {
    label: 'Ahmedabad',
    value: 'Ahmedabad'
  },
  {
    label: 'Chennai',
    value: 'Chennai'
  },
  {
    label: 'Kolkata',
    value: 'Kolkata'
  },
  {
    label: 'Pune',
    value: 'Pune'
  },
  {
    label: 'Jaipur',
    value: 'Jaipur'
  },
  {
    label: 'Surat',
    value: 'Surat'
  },
  {
    label: 'Lucknow',
    value: 'Lucknow'
  },
  {
    label: 'Kanpur',
    value: 'Kanpur'
  },
  {
    label: 'Nagpur',
    value: 'Nagpur'
  },
  {
    label: 'Indore',
    value: 'Indore'
  },
  {
    label: 'Thane',
    value: 'Thane'
  },
  {
    label: 'Bhopal',
    value: 'Bhopal'
  },
  {
    label: 'Visakhapatnam',
    value: 'Visakhapatnam'
  },
  {
    label: 'Patna',
    value: 'Patna'
  },
  {
    label: 'Vadodara',
    value: 'Vadodara'
  },
  {
    label: 'Ghaziabad',
    value: 'Ghaziabad'
  }
];

export const defaultBankList = [
  {
    label: 'State Bank of India',
    value: 'State Bank of India'
  },
  {
    label: 'HDFC Bank',
    value: 'HDFC Bank'
  },
  {
    label: 'ICICI Bank',
    value: 'ICICI Bank'
  },
  {
    label: 'Punjab National Bank',
    value: 'Punjab National Bank'
  },
  {
    label: 'Bank of Baroda',
    value: 'Bank of Baroda'
  },
  {
    label: 'Axis Bank',
    value: 'Axis Bank'
  },
  {
    label: 'Kotak Mahindra Bank',
    value: 'Kotak Mahindra Bank'
  },
  {
    label: 'Union Bank of India',
    value: 'Union Bank of India'
  },
  {
    label: 'Canara Bank',
    value: 'Canara Bank'
  },
  {
    label: 'Bank of India',
    value: 'Bank of India'
  },
  {
    label: 'IndusInd Bank',
    value: 'IndusInd Bank'
  },
  {
    label: 'IDBI Bank',
    value: 'IDBI Bank'
  },
  {
    label: 'Yes Bank',
    value: 'Yes Bank'
  },
  {
    label: 'Indian Bank',
    value: 'Indian Bank'
  },
  {
    label: 'Central Bank of India',
    value: 'Central Bank of India'
  },
  {
    label: 'Federal Bank',
    value: 'Federal Bank'
  },
  {
    label: 'UCO Bank',
    value: 'UCO Bank'
  },
  {
    label: 'Punjab & Sind Bank',
    value: 'Punjab & Sind Bank'
  },
  {
    label: 'Indian Overseas Bank',
    value: 'Indian Overseas Bank'
  },
  {
    label: 'IDFC First Bank',
    value: 'IDFC First Bank'
  }
];

