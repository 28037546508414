const { GET_PERMISSION_LIST } = require("redux/contants");
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FmsService } from 'service/fms';
import {getPermissionListError,getPermissionListSuccess} from "./action"
import { Toaster } from 'components/toaster/Toaster';

const server = new FmsService();

export function* watchPermissionList() {
    yield takeEvery(GET_PERMISSION_LIST, fetchStateList);
  };


  const fetchPermissionListFromServer = async (payload) => (
    await server.getPermissionList().then((response) => (response)).catch((error) => (error))
  );


  function* fetchStateList(payload) {
    try {
      const response = yield call(fetchPermissionListFromServer, {});
      if (response?.data?.success) {
          const states = response?.data?.data?.map((state) => ({...state, label: state?.name, value: state?.code}));
          yield put(getPermissionListSuccess(states));
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      yield put(getPermissionListError(error));
      toaster.show(true, error?.message);
    }
  };



  export default function* permissionSaga() {
    yield all([
      fork(watchPermissionList),
    ]);
};