import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_EV_DETAILS_ON_TYPE, GET_STATE_LIST } from 'redux/contants';
import { UserService } from 'service/user';
import { getEVDetailsError, getEVDetailsSuccess, getStateListError, getStateListSuccess } from './action';

const server = new UserService();

export function* watchStateList() {
  yield takeEvery(GET_STATE_LIST, fetchStateList);
};

export function* watchEVDetails() {
    yield takeEvery(GET_EV_DETAILS_ON_TYPE, fetchEVDetails);
}


const fetchStateListFromServer = async (payload) => (
  await server.getStateList().then((response) => (response)).catch((error) => (error))
);

const fetchEVDetailsFromServer = async (payload) => (
    await server.getEvDetails().then((response) => (response)).catch((error) => (error))
)


function* fetchStateList(payload) {
  try {
    const response = yield call(fetchStateListFromServer, {});
    if (response?.data?.success) {
        const states = response?.data?.data?.map((state) => ({...state, label: state?.name, value: state?.code}));
        yield put(getStateListSuccess(states));
    } else {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getStateListError(error));
    toaster.show(true, error?.message);
  }
};

function* fetchEVDetails(payload) {
    try {
        const response = yield call(fetchEVDetailsFromServer, {});
        if (response?.data?.success) {
            const details = response?.data?.data?.map((det) => ({...det, label: det?.code, value: det?.code}));
            yield put(getEVDetailsSuccess(details));
        } else {
          throw new Error(response?.message);
        }
      } catch (error) {
        yield put(getEVDetailsError(error));
        toaster.show(true, error?.message);
    }
}

export default function* calcSaga() {
    yield all([
      fork(watchStateList),
      fork(watchEVDetails)
    ]);
};