import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { UserService } from 'service/user';
import {
  GET_CONSOLIDATED_DISTANCE_AND_CO2,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE,
  GET_CONSOLIDATED_LOCATION,
  GET_CONSOLIDATED_VEHICLE_DATA,
    GET_INDIVIDUAL_VEHICLE_DATA,
    GET_VEHICLE_DATA,
    GET_VEHICLE_LIST
} from '../contants';
import { Toaster } from 'components/toaster/Toaster';
import { 
  getConsolidatedDistanceAndCo2Error,
  getConsolidatedDistanceAndCo2OnDateError,
  getConsolidatedDistanceAndCo2OnDateSuccess,
  getConsolidatedDistanceAndCo2Success,
  getConsolidatedLocationError,
  getConsolidatedLocationSuccess,
  getConsolidatedVehicleDataError,
    getConsolidatedVehicleDataSuccess,
    getIndividualVehicleDataError,
    getIndividualVehicleDataSuccess,
    getVehicleDataError,
    getVehicleListError,
    getVehicleListSuccess,
    saveOEMData,
} from './actions';

const server = new UserService();
const toaster = new Toaster();

function* watchVehicleData() {
  yield takeEvery(GET_VEHICLE_DATA, fetchOemList);
}

function* watchConsolidatedVehicleData() {
  yield takeEvery(GET_CONSOLIDATED_VEHICLE_DATA, fetchConsolidatedVehicleData);
}

function* watchConsolidatedDistanceAndCo2() {
  yield takeEvery(GET_CONSOLIDATED_DISTANCE_AND_CO2, fetchConsolidatedDistanceAndCo2);
}

function* watchConsolidatedDistanceAndCo2OnDate() {
  yield takeEvery(GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE, fetchConsolidatedDistanceAndCo2OnDate);
}

function* watchConsolidatedLocation() {
  yield takeEvery(GET_CONSOLIDATED_LOCATION, fetchConsolidatedLocation);
}

function* watchVehicleList() {
  yield takeEvery(GET_VEHICLE_LIST, fetchVehicleList);
}

function* watchIndividualVehicleData() {
  yield takeEvery(GET_INDIVIDUAL_VEHICLE_DATA, fetchIndividualVehicleData);
}


const fetchVehicleDataFromServer = async (payload) => (
  await server.getOEMMakerList(payload).then((response) => (response)).catch((error) => (error))
)

const fetchVehicleListFromServer = async (payload) => (
  await server.getVehicleList().then((response) => (response)).catch((error) => (error))
)

const fetchConsolidatedLocationFromServer = async (payload) => (
  await server.getConsolidatedLocation().then((response) => (response)).catch((error) => (error))
)

const fetchConsolidatedVehicleDataFromServer = async (payload) => (
  await server.getConsolidatedVehicleData(payload).then((response) => (response)).catch((error) => (error))
);

const fetchConsolidatedDistanceAndCo2FromServer = async (payload) => (
  await server.getConsolidatedDistanceAndCo2(payload).then((response) => (response)).catch((error) => (error))
);

const fetchConsolidatedDistanceAndCo2OnDateFromServer = async (payload) => (
  await server.getConsolidatedDistanceAndCo2OnDate(payload).then((response) => (response)).catch((error) => (error))
)

const fetchIndividualVehicleDataFromServer = async (payload) => (
  await server.getIndividualVehicleData(payload).then((response) => (response)).catch((error) => (error))
)

function* fetchOemList(form) {
  try {
    const { action, payload } = form.payload;
    const response = yield call(fetchVehicleDataFromServer, payload);
    if(response?.data?.success){
        yield put(saveOEMData(action, response?.data?.data))
    } else {
        throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getVehicleDataError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* computeOverConsolidatedVehicleData(data) {
  let batteryPercent = {
    'lessThan20': 0,
    '20To50': 0,
    '50To80': 0,
    'moreThan80': 0
  };
  let temperature = {
    'lessThan15': 0,
    '15To30': 0,
    '30To40': 0,
    'moreThan40': 0
  };
  let vehicle = {
    'charging': 0,
    'discharging': 0,
    'idle': 0
  };
  let trackingStatus = 0;
  for(let i = 0; i < data?.length; i++){
    const obj = data[i];
    if(obj?.battery_percentage <= 20){
      batteryPercent['lessThan20']++;
    } else if(obj?.battery_percentage > 20 && obj?.battery_percentage <=50){
      batteryPercent['20To50']++;
    } else if(obj?.battery_percentage > 50 && obj?.battery_percentage <=80){
      batteryPercent['50To80']++;
    } else if(obj?.battery_percentage > 80){
      batteryPercent['moreThan80']++;
    }

    if(obj?.temperature <= 15 ){
      temperature['lessThan15']++;
    } else if(obj?.temperature > 15 && obj?.temperature <= 30){
      temperature['15To30']++;
    } else if(obj?.temperature > 30 && obj?.temperature <= 40){
      temperature['30To40']++;
    } else if(obj?.temperature > 40){
      temperature['moreThan40']++
    }

    switch(obj?.vehicleStatus){
      case 'Charging':
        vehicle['charging']++;
        break;
      case 'DisCharging':
        vehicle['discharging']++;
        break;
      case 'Idle':
        vehicle['idle']++;
        break;
    }
    if(obj?.isLatestData =='true'){
      trackingStatus++;
    }
  }

  const track = `${trackingStatus}/${data?.length}`;

  return {vehicle, temperature, batteryPercent, trackingStatus: track};
}

function* fetchConsolidatedVehicleData(payload) {
  try {
      const response = yield call(fetchConsolidatedVehicleDataFromServer, {});
      if(response?.data?.success){

        const { vehicle, batteryPercent, temperature, trackingStatus } = yield call(computeOverConsolidatedVehicleData, response?.data?.data);
        const batteryStatus = [batteryPercent?.['lessThan20'], batteryPercent?.['20To50'], batteryPercent?.['50To80'], batteryPercent?.['moreThan80']];
        const temperatureStatus = [temperature?.['lessThan15'], temperature?.['15To30'], temperature?.['30To40'], temperature?.['moreThan40']];
        const vehicleStatus = [vehicle?.['charging'], vehicle?.['discharging'], vehicle?.['idle']];

        yield put(getConsolidatedVehicleDataSuccess({data: response?.data?.data, batteryStatus, temperatureStatus, vehicleStatus, trackingStatus}));
      } else {
        throw new Error(response?.message);
      }
  } catch(error){
    toaster.show(true, error?.message);
    yield put(getConsolidatedVehicleDataError(error?.message));
  }
}

function* fetchConsolidatedDistanceAndCo2({payload}) {
  try {
    const response = yield call(fetchConsolidatedDistanceAndCo2FromServer, payload?.payload);
    if(response?.data?.success){
      let distance = [];
      let co2 = [];
      response?.data?.data?.forEach((data) => {
        if(payload?.key === 'distance'){
          distance = [...distance, data?.distanceTravelled];
        } else if(payload?.key === 'co2'){
          co2 = [...co2, data?.CO2Saved];
        } else {
          distance = [...distance, data?.distanceTravelled];
          co2 = [...co2, data?.CO2Saved];
        }
      })
      yield put(getConsolidatedDistanceAndCo2Success({data: response?.data?.data, distance, co2, key: payload?.key}));
    } else {
      throw new Error(response?.message);
    }
  } catch(error) {
    toaster.show(true, error?.message);
    yield put(getConsolidatedDistanceAndCo2Error(error?.message));
  }
}

function* fetchConsolidatedDistanceAndCo2OnDate({payload}) {
  try {
    const response = yield call(fetchConsolidatedDistanceAndCo2OnDateFromServer, payload);
    if(response?.data?.success){
      yield put(getConsolidatedDistanceAndCo2OnDateSuccess(response?.data?.data));
    } else {
      throw new Error(response?.message);
    }
  } catch(error){
    toaster.show(true, error?.message);
    yield put(getConsolidatedDistanceAndCo2OnDateError(error?.message));
  }
}

function* fetchConsolidatedLocation(payload) {
  try {
    const response = yield call(fetchConsolidatedLocationFromServer, {});
    if(response?.data?.success){
      yield put(getConsolidatedLocationSuccess(response?.data?.data?.filter((loc) => (loc?.latitude != 0 && loc?.longitude != 0))));
    } else {
      yield put(getConsolidatedLocationError(response?.message));
      throw new Error(response?.message);
    }
  } catch(error){
    toaster.show(true, error?.message);
  }
}

function* fetchVehicleList(payload) {
  try {
    const response = yield call(fetchVehicleListFromServer, {});
    if(response?.data?.success){
      yield put(getVehicleListSuccess(response?.data?.data));
    } else {
      yield put(getVehicleListError(response?.message));
      throw new Error(response?.message);
    }
  } catch(error){
    toaster.show(true, error?.message);
  }
}

function* fetchIndividualVehicleData(payload) {
  try {
    const response = yield call(fetchIndividualVehicleDataFromServer, payload?.payload);
    if(response?.data?.success){
      yield put(getIndividualVehicleDataSuccess(response?.data?.data));
    } else {
      yield put(getIndividualVehicleDataError(response?.message));
      throw new Error(response?.message);
    }
  } catch(error){
    toaster.show(true, error?.message);
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchVehicleData),
    fork(watchConsolidatedVehicleData),
    fork(watchConsolidatedDistanceAndCo2),
    fork(watchConsolidatedDistanceAndCo2OnDate),
    fork(watchConsolidatedLocation),
    fork(watchVehicleList),
    fork(watchIndividualVehicleData)
  ]);
}
