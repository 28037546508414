import { GET_EV_DETAILS_ON_TYPE, GET_EV_DETAILS_ON_TYPE_ERROR, GET_EV_DETAILS_ON_TYPE_SUCCESS, GET_STATE_LIST, GET_STATE_LIST_ERROR, GET_STATE_LIST_SUCCESS } from "redux/contants";


export const getStateList = (payload) => ({
    type: GET_STATE_LIST,
    payload
});

export const getStateListSuccess = (payload) => ({
    type: GET_STATE_LIST_SUCCESS,
    payload
});

export const getStateListError = (payload) => ({
    type: GET_STATE_LIST_ERROR,
    payload
});

export const getEVDetails = (payalod) => ({
    type: GET_EV_DETAILS_ON_TYPE,
    payalod
});

export const getEVDetailsSuccess = (payload) => ({
    type: GET_EV_DETAILS_ON_TYPE_SUCCESS,
    payload
});

export const getEVDetailsError = (payalod) => ({
    type: GET_EV_DETAILS_ON_TYPE_ERROR,
    payalod
});