import Vehicle from '../assets/gif/car (1).gif';
import Infra from '../assets/gif/settings.gif';
import Working from '../assets/gif/rocket.gif';
import Parts from '../assets/gif/chassis.gif';
import Loan from '../assets/gif/discount-bag.gif';
import Lease from '../assets/gif/keys.gif';
import VehicleInsurance from '../assets/gif/car-insurance.gif';
import FinancialLease from '../assets/gif/for-rent.gif';
import OperatingLease from '../assets/gif/financial-freedom.gif';
import Insurance from '../assets/gif/last-will.gif';
import PersonalInsurance from '../assets/gif/medical-insurance.gif';
import FleetManagement from '../assets/gif/fleet-management.gif';
import SASS from '../assets/gif/sass.gif';

export const fieldConstants = {
    'dateOfBirth': {
        label: 'Date of birth',
        code: 'dateOfBirth',
        placeholder: 'DD/MM/YYYY'
    },
    'gender': {
        label: 'Gender',
        code: 'gender',
        placeholder: 'Gender'
    },
    'pincode': {
        label: 'Pincode',
        code: 'pincode',
        placeholder: 'Pincode',
    },
    'address': {
        label: 'Address',
        code: 'address',
        placeholder: 'Address'
    },
    'nomineeName': {
        label: 'Nominee Name',
        code: 'nomineeName',
        placeholder: 'Nominee Name'
    },
    'relationshipWithNominee': {
        label: 'Relationship with nominee',
        code: 'relationshipWithNominee',
        placeholder: 'Relationship with nominee'
    },
    'purposeOfVisit': {
        label: 'Purpose of visit',
        code: 'purposeOfVisit',
        placeholder: 'Purpose of visit'
    },
    'productType': {
        label: 'Product type',
        code: 'productType',
        placeholder: 'Product type'
    },
    'rtoCode': {
        label: 'RTO code',
        code: 'rtoCode',
        placeholder: 'RTO Code'
    },
    'registrationNumber': {
        label: 'Vehicle registration number',
        code: 'registrationNumber',
        placeholder: 'Registration Number'
    },
    'fuelType': {
        label: 'Fuel type',
        code: 'fuelType',
        placeholder: 'Fuel type'
    },
    'seatingCapacity': {
        label: 'Seating capacity',
        code: 'seatingCapacity',
        placeholder: 'Seating capacity'
    },
    'mfy': {
        label: 'Manufacturing year',
        code: 'mfy',
        placeholder: 'Manufacturing year'
    },
    'policyType': {
        label: 'Policy type',
        code: 'policyType',
        placeholder: 'Policy type'
    },
    'nilDep': {
        label: 'Nil Depreciation',
        code: 'nilDep',
        placeholder: ''
    },
    'rti': {
        label: 'RTI',
        code: 'rti',
        placeholder: ''
    },
    'rsa': {
        label: 'Road side assistance',
        code: 'rsa',
        placeholder: ''
    },
    'eme': {
        label: 'EME',
        code: 'eme',
        placeholder: ''
    },
    'batteryCover': {
        label: 'Battery cover',
        code: 'batteryCover',
        placeholder: ''
    },
    'cpa': {
        label: 'CPA',
        code: 'cpa',
        placeholder: ''
    },
    'imt23': {
        label: 'IMT23',
        code: 'imt23',
        placeholder: ''
    },
    'ownerDriver': {
        label: 'Owner driver',
        code: 'ownerDriver',
        placeholder: ''
    },
    'designation': {
        label: 'Your designation',
        code: 'designation',
        placeholder: ''
    },
    'department': {
        label: 'Your department',
        code: 'department',
        placeholder: ''
    },
    'entityType': {
        label: 'Entity Type',
        code: 'entityType',
        placeholder: 'Entity Type'
    },
    'topPartners': {
        label: 'Top',
        code: 'topPartners',
        placeholder: ''
    },
    'typeOfBusiness': {
        label: 'Primary nature Of Business',
        code: 'typeOfBusiness',
        placeholder: 'Type Of Business',
    },
    'businessCategory': {
        label: 'Form factor(s)',
        code: 'businessCategory',
        placeholder: 'Business Category',
    },
    'businessName': {
        label: 'Business Name(As per business PAN)',
        code: 'businessName',
        placeholder: 'Business Name',
    },
    'businessIncorporationYear': {
        label: 'Business Incorporation Date(DD/MM/YYYY)',
        code: 'businessIncorporationYear',
        placeholder: 'DD/MM/YYYY',
    },
    'existingVehicles': {
        label: 'Existing assets (vehicle/charging point)',
        code: 'existingVehicles',
        placeholder: 'Existing Vehicles',
    },
    'numberOfVehicleSold': {
        label: 'Number of vehicle sold',
        code: 'numberOfVehicleSold',
        placeholder: 'Number of vehicle sold'
    },
    'numberOfBatterySold': {
        label: 'Number of battery sold',
        code: 'numberOfBatterySold',
        placeholder: 'Number of battery sold'
    },
    'revenueLastAudited': {
        label: 'Revenue(Audited)',
        code: 'revenueLastAudited',
        placeholder: 'Revenue(Audited)',
    },
    'pbtLastAudited': {
        label: 'Profit Before Tax(Audited)',
        code: 'pbtLastAudited',
        placeholder: 'Profit Before Tax(Audited)',
    },
    'lastProvisionalRevenue': {
        label: 'Revenue(Provisional)',
        code: 'lastProvisionalRevenue',
        placeholder: 'Revenue(Provisional)',
    },
    'pbtLastProvisional': {
        label: 'Profit Before Tax(Provisional)',
        code: 'pbtLastProvisional',
        placeholder: 'Profit Before Tax(Provisional)',
    },
    'projectedRevenue': {
        label: 'Projected Revenue',
        code: 'projectedRevenue',
        placeholder: 'Projected Revenue',
    },
    'pbtProjected': {
        label: 'Projected Profit Before Tax',
        code: 'pbtProjected',
        placeholder: 'Projected Profit Before Tax',
    },
    'topDebtors': {
        label: 'Top 3 Debtors',
        code: 'topDebtors',
        placeholder: 'Debtors',
    },
    'revenueBreakup': {
        label: 'Revenue Breakup',
        code: 'revenueBreakup',
        placeholder: 'Revenue Breakup'
    },
    'amountRequired': {
        label: 'Loan Amount',
        code: 'amountRequired',
        placeholder: 'Loan Amount',
    },
    'city': {
        label: 'City',
        code: 'city',
        placeholder: 'City',
    },
    'numberOfVehicleRequired': {
        label: 'Vehicles Required(In units)',
        code: 'numberOfVehicleRequired',
        placeholder: 'Vehicles Required(In units)',
    },
    'vehicleAssistance': {
        label: 'I need assistance',
        code: 'vehicleAssistance',
        placeholder: ''
    },
    'manufacturer': {
        label: 'Manufacturer',
        code: 'manufacturer',
        placeholder: 'Manufacturer',
    },
    'model': {
        label: 'Model',
        code: 'model',
        placeholder: 'Model',
    },
    'variant': {
        label: 'Variant',
        code: 'variant',
        placeholder: 'Variant',
    },
    'cin': {
        label: 'Corporate Identification Number(CIN)',
        code: 'cin',
        placeholder: 'CIN',
    },
    'udyam': {
        label: 'Udyam Number',
        code: 'udyam',
        placeholder: 'Udyam Number',
    },
    'gstinNumber': {
        label: 'GST Number',
        code: 'gstinNumber',
        placeholder: 'GST Number',
    },
    'aadharOtp': {
        label: 'Enter OTP',
        code: 'aadharOtp',
        placeholder: 'Enter OTP',
    },
    'aadhar': {
        label: 'Aadhar Number',
        code: 'aadhar',
        placeholder: 'Aadhar Number',
    },
    'pan': {
        label: 'PAN',
        code: 'pan',
        placeholder: 'PAN',
    },
    'directorMobile': {
        label: 'Mobile Number',
        code: 'directorMobile',
        placeholder: 'Mobile Number',
    },
    'financialStatementLastFY': {
        label: `Financial Statement (FY ${new Date().getFullYear()-1}-${new Date().getFullYear()})`,
        code: 'financialStatementLastFY',
        placeholder: 'Financial Statement',
    },
    'financialStatementLastFY-1': {
        label: `Financial Statement (FY ${new Date().getFullYear()-2}-${new Date().getFullYear()-1})`,
        code: 'financialStatementLastFY-1',
        placeholder: 'Financial Statement',
    },
    'financialStatementLastFY-2': {
        label: `Financial Statement (FY ${new Date().getFullYear()-3}-${new Date().getFullYear()-2})`,
        code: 'financialStatementLastFY-2',
        placeholder: 'Financial Statement',
    },
    'bankStatement': {
        label: 'Bank Statement',
        code: 'bankStatement',
        placeholder: 'Bank Statement',
    },
    'loanStatementForExistingLoan': {
        label: 'Loan Statement for existing loan',
        code: 'loanStatementForExistingLoan',
        placeholder: 'Loan Statement for existing loan',
    },
    'incomeTaxReturn': {
        label: 'Income Tax Return',
        code: 'incomeTaxReturn',
        placeholder: 'Income Tax Return',
    },
    'gstReturn': {
        label: 'GST Returns',
        code: 'gstReturn',
        placeholder: 'GST Returns',
    },
    'sanctionLetterOfLoanLease': {
        label: 'Sanction Letter of Lease Loan',
        code: 'sanctionLetterOfLoanLease',
        placeholder: 'Sanction Letter of Lease Loan',
    },
    'unitEconomics': {
        label: 'Unit Economics',
        code: 'unitEconomics',
        placeholder: 'Unit Economics',
    },
    'shareHolding': {
        label: 'Shareholding pattern',
        code: 'shareHolding',
        placeholder: 'Shareholding pattern',
    },
    'companyProfile': {
        label: 'Company Profile',
        code: 'companyProfile',
        placeholder: 'Company Profile',
    },
    'memorandumOfAssociation': {
        label: 'Memorandum of Association',
        code: 'memorandumOfAssociation',
        placeholder: 'Memorandum of Association',
    },
    'partnershipDeed': {
        label: 'Partnership Deed',
        code: 'partnershipDeed',
        placeholder: 'Partnership Deed',
    },
    'balanceSheet': {
        label: 'Balance Sheet',
        code: 'balanceSheet',
        placeholder: 'Balance Sheet',
    },
    'profitLossStatement': {
        label: 'Profit Loss Statement',
        code: 'profitLossStatement',
        placeholder: 'Profit Loss Statement',
    },
    'projections': {
        label: 'Projections',
        code: 'projections',
        placeholder: 'Projections',
    }
};

export const basicInfoFields = [
    {
        label: 'Date of birth',
        code: 'dateOfBirth',
        placeholder: 'Date of birth'
    },
    {
        label: 'Gender',
        code: 'gender',
        placeholder: 'Gender'
    },
    {
        label: 'Pincode',
        code: 'pincode',
        placeholder: 'Pincode'
    },
    {
        label: 'Address',
        code: 'address',
        placeholder: 'Address'
    },
    {
        label: 'Nominee name',
        code: 'nomineeName',
        placeholder: 'Nominee name'
    },
    {
        label: 'Relationship with nominee',
        code: 'relationshipWithNominee',
        placeholder: 'Relationship with nominee'
    },
    {
        label: 'Purpose of visit',
        code: 'purposeOfVisit',
        placeholder: 'Purpose of visit'
    },
    {
        label: 'Product type',
        code: 'productType',
        placeholder: 'Product type'
    },
    {
        label: 'RTO code',
        code: 'rtoCode',
        placeholder: 'RTO code'
    },
    {
        label: 'Registration number',
        code: 'registrationNumber',
        placeholder: 'Registration number'
    },
    {
        label: 'Designation',
        code: 'designation',
        placeholder: 'Designation'
    },
    {
        label: 'Department',
        code: 'department',
        placeholder: 'department'
    },
    {
        label: 'Entity type',
        code: 'entityType',
        placeholder: 'Entity type'
    },
    {
        label: 'Type of business',
        code: 'typeOfBusiness',
        placeholder: 'Type of business',
    },
    {
        label: 'Business category',
        code: 'businessCategory',
        placeholder: 'Business category',
    },
    {
        label: 'Business name(As per business PAN)',
        code: 'businessName',
        placeholder: 'Business name',
    },
    {
        label: 'Business incorporation date(DD/MM/YYYY)',
        code: 'businessIncorporationYear',
        placeholder: 'DD/MM/YYYY',
    }
];

export const businessInfoFields = [
    {
        label: 'Existing vehicles(on book/leased/aggregated)',
        code: 'existingVehicles',
        placeholder: 'Existing vehicles',
    },
    {
        label: 'Number of vehicle sold',
        code: 'numberOfVehicleSold',
        placeholder: 'Number of vehicle sold'
    },
    {
        label: 'Number of battery sold',
        code: 'numberOfBatterySold',
        placeholder: 'Number of battery sold'
    },
    {
        label: 'Top partners',
        code: 'topPartners',
        placeholder: 'Top partners'
    },
    {
        label: 'Revenue(Audited)',
        code: 'revenueLastAudited',
        placeholder: 'Revenue(Audited)',
        input: 'money'
    },
    {
        label: 'Profit before tax(Audited)',
        code: 'pbtLastAudited',
        placeholder: 'Profit before tax(Audited)',
        input: 'money'
    },
    {
        label: 'Revenue(Provisional)',
        code: 'lastProvisionalRevenue',
        placeholder: 'Revenue(Provisional)',
        input: 'money'
    },
    {
        label: 'Profit before tax(Provisional)',
        code: 'pbtLastProvisional',
        placeholder: 'Profit before tax(Provisional)',
        input: 'money'
    },
    {
        label: 'Projected revenue',
        code: 'projectedRevenue',
        placeholder: 'Projected revenue',
        input: 'money'
    },
    {
        label: 'Projected profit before tax',
        code: 'pbtProjected',
        placeholder: 'Projected profit before tax',
        input: 'money'
    },
    {
        label: 'Top 3 debtors',
        code: 'topDebtors',
        placeholder: 'Top 3 debtor',
    }
]

export const requirementFields = [
    {
        label: 'Loan amount',
        code: 'amountRequired',
        placeholder: 'Loan amount',
        input: 'money'
    },
    {
        label: 'city',
        code: 'city',
        placeholder: 'City',
    },
    {
        label: 'Vehicles required(In units)',
        code: 'numberOfVehicleRequired',
        placeholder: 'Vehicles required(In units)',
    },
    {
        label: 'Manufacturer',
        code: 'manufacturer',
        placeholder: 'Manufacturer',
    },
    {
        label: 'Model',
        code: 'model',
        placeholder: 'Model',
    },
    {
        label: 'Variant',
        code: 'variant',
        placeholder: 'Variant',
    },
    {
        label: 'Vehicle assistance',
        code: 'vehicleAssistance',
        placeholder: 'Vehicle assistance'
    },
    {
        label: 'Fuel type',
        code: 'fuelType',
        placeholder: 'Fuel type'
    },
    {
        label: 'Seating capacity',
        code: 'seatingCapacity',
        placeholder: 'Seating capacity'
    },
    {
        label: 'MFY',
        code: 'mfy',
        placeholder: 'MFY'
    },
    {
        label: 'Policy type',
        code: 'policyType',
        placeholder: 'Policy type'
    },
    {
        label: 'NIL dep',
        code: 'nilDep',
        placeholder: ''
    },
    {
        label: 'RTI',
        code: 'rti',
        placeholder: ''
    },
    {
        label: 'RSA',
        code: 'rsa',
        placeholder: ''
    },
    {
        label: 'EME',
        code: 'eme',
        placeholder: ''
    },
    {
        label: 'Battery cover',
        code: 'batteryCover',
        placeholder: ''
    },
    {
        label: 'CPA',
        code: 'cpa',
        placeholder: '',
    },
    {
        label: 'IMT23',
        code: 'imt23',
        placeholder: ''
    },
    {
        label: 'Owner driver',
        code: 'ownerDriver',
        placeholder: ''
    }
];

export const businessKycFields = [
    {
        label: 'Corporate identification number(CIN)',
        code: 'cin',
        placeholder: 'CIN',
    },
    {
        label: 'Udyam number',
        code: 'udyam',
        placeholder: 'Udyam number',
    },
    {
        label: 'GST number',
        code: 'gstin',
        placeholder: 'GST number',
    },
    {
        label: 'Company PAN',
        code: 'pan',
        placeholder: ''
    }
]


export const categories = [
    {
        label: 'Loan',
        img: Loan,
        code: 'loan',
        value: 'a loan for',
        type: 'category'
    },
    {
        label: 'Lease',
        img: Lease,
        code: 'lease',
        value: 'a lease ?',
        type: 'category'
    },
    {
        label: 'Insurance',
        img: Insurance,
        code: 'insurance',
        value: 'an insurance ?',
        type: 'category'
    },
    // {
    //     label: 'Software as a Service',
    //     img: SASS,
    //     code: 'software',
    //     value: 'a software',
    //     type: 'category'
    // }
];

export const loanSubCategory = [

    {
        label: 'Buying EV',
        img: Vehicle,
        code: 'vehicleLoan',
        value: 'buying a',
        type: 'subCategory'
    },
    {
        label: 'My business',
        img: Working,
        code: 'businessLoan',
        value: 'business',
        type: 'subCategory'
    },
    {
        label: 'Charging infrastructure',
        img: Infra,
        code: 'infraLoan',
        value: 'setting up charging infrastructure',
        type: 'subCategory'
    },
    {
        label: 'Batteries and Others',
        img: Parts,
        code: 'otherLoan',
        value: 'Batteries and Others',
        type: 'subCategory'
    }
];

export const leaseSubCategory = [
    {
        label: 'Operating Lease',
        img: OperatingLease,
        code: 'operatingLease',
        value: 'operating lease for',
        type: 'subCategory'
    },
    {
        label: 'Financial Lease',
        img: FinancialLease,
        code: 'financialLease',
        value: 'financial lease for',
        type: 'subCategory'
    }
];

export const insuranceSubCategory = [
    {
        label: 'Vehicle insurance',
        img: VehicleInsurance,
        code: 'vehicleInsurance',
        value: 'a vehicle insurance for',
        type: 'subCategory'
    },
    {
        label: 'Personal insurance',
        img: PersonalInsurance,
        code: 'personalInsurance',
        value: 'a personal insurance for',
        type: 'subCategory'
    }
]

export const sassSubCategory = [
    {
        label: 'Fleet Management Software',
        img: FleetManagement,
        code: 'fleetManagement',
        value: 'a fleet management software',
        type: 'subCategory'
    }
]


export const productSelectionComments = ["Hold on...", "We are unpacking things for you...", "Just a moment...."];
