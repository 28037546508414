import { axiosInstance, formDataHeaders } from "./base";
import { headers } from "./base";

export class JourneyService {

    COS_BASE_URL= process.env.REACT_APP_COS_BASE_URL;

    requestOtp = (payload) => {
        return axiosInstance.post(`${this.COS_BASE_URL}/auth`, payload, {headers: headers()});
    }

    verifyOtp = (payload) => {
        return axiosInstance.post(`${this.COS_BASE_URL}/auth`, payload, {headers: headers()});
    }

    getProductList = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/products`, {headers: headers()});
    }

    submitProducts = (payload) => {
        return axiosInstance.post(`${this.COS_BASE_URL}/products`, payload, {headers: headers()});
    }

    getUserDetails = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/customer-details`, {headers: headers()});
    }

    updateUserDetails = (payload) => {
        return axiosInstance.patch(`${this.COS_BASE_URL}/customer-details`, payload, {headers: headers()});
    }

    updateUserDetailsFormData = (payload) => {
        return axiosInstance.patch(`${this.COS_BASE_URL}/customer-details`, payload, {headers: formDataHeaders()});
    }

    sendLinkForKyc = (payload) => {
        return axiosInstance.post(`${this.COS_BASE_URL}/send-link`, payload, {headers: headers()});
    }

    getDirectorDetails = (payload) => {
        return axiosInstance.get(`${this.COS_BASE_URL}/director/info/${payload}`, {headers: headers()});
    }

    getDirectorList = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/directors`, {headers: headers()});
    }

    getMouDetails = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/agreement/customer`, {headers: headers()});
    }

    updateDocument = (payload) => {
        return axiosInstance.post(`${this.COS_BASE_URL}/agreement/customer`, payload, {headers: headers()});
    }

    getBreResult = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/application-status`, {headers: headers()});
    }

    getApplicationSummary = () => {
        return axiosInstance.get(`${this.COS_BASE_URL}/application-summary`, {headers: headers()});
    }

    getCityList = (search='') => {
        return axiosInstance.get(`${this.COS_BASE_URL}/pincode/cities?search=${search}`, {headers: headers()});
    }

    getBankList = (search='') => {
        return axiosInstance.get(`${this.COS_BASE_URL}/banks?search=${search}`, {headers: headers()});
    }

    verifyUserEmail = (token) => {
        return axiosInstance.get(`${this.COS_BASE_URL}/auth/email?token=${token}`, {headers: headers()});
    }


}