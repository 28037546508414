import { getToken } from 'helpers/Utils';
import { isAuthGuardActive, currentUser } from 'constants/defaultValues';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  FLEETBASE_LOGIN,
  FLEETBASE_LOGIN_SUCCESS,
  FLEETBASE_LOGIN_ERROR
} from '../contants';

const INIT_STATE = {};

export const userDetails =  (state = INIT_STATE, action) => {

  switch (action.type) {
    case LOGIN_USER:
      return {...state};
    case LOGIN_USER_SUCCESS: 
      return action?.payload;
    case LOGIN_USER_ERROR: 
      return {...state}
    case REGISTER_USER:
      return { ...state};
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    default:
      return { ...state };
  }
};

const fleetbaseState = {
  token: null,
  windowOpened: false,
  loading: false,
  error: null
}

export const fleetbaseLoginReducer = (state=fleetbaseState, action) => {
  switch(action?.type) {
    case FLEETBASE_LOGIN:
      return {
        ...state,
        loading: true
      };
    case FLEETBASE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        token: action?.payload?.token
      };
    case FLEETBASE_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
        token: null,
        windowOpened: false
      };
    default:
      return state;
  }
}
