import { 
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_ERROR,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_ORDER,
    CREATE_NEW_ORDER_ERROR,
    CREATE_NEW_ORDER_SUCCESS,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_ERROR,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_DRIVER_LIST,
    GET_DRIVER_LIST_ERROR,
    GET_DRIVER_LIST_SUCCESS,
    GET_FMS_VEHICLE_LIST,
    GET_FMS_VEHICLE_LIST_ERROR,
    GET_FMS_VEHICLE_LIST_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_ERROR,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_LIST, 
    GET_ORDER_LIST_ERROR, 
    GET_ORDER_LIST_SUCCESS, 
    PICKUP_AND_DROP
} from "redux/contants";


export const getOrderList = (payload) => ({
    type: GET_ORDER_LIST,
    payload
});

export const getOrderListSuccess = (payload) => ({
    type: GET_ORDER_LIST_SUCCESS,
    payload
});

export const getOrderListError = (payload) => ({
    type: GET_ORDER_LIST_ERROR,
    payload
});

export const getOrderDetails = (payload) => ({
    type: GET_ORDER_DETAILS,
    payload
});

export const getOrderDetailsSuccess = (payload) => ({
    type: GET_ORDER_DETAILS_SUCCESS,
    payload
});

export const getOrderDetailsError = (payload) => ({
    type: GET_ORDER_DETAILS_ERROR,
    payload
})

export const updatePickupAndDrop = (payload) => ({
    type: PICKUP_AND_DROP,
    payload
});

export const createNewOrder = (payload) => ({
    type: CREATE_NEW_ORDER,
    payload
});

export const createNewOrderSuccess = (payload) => ({
    type: CREATE_NEW_ORDER_SUCCESS,
    payload
});

export const createNewOrderError = (payload) => ({
    type: CREATE_NEW_ORDER_ERROR,
    payload
});

export const createNewCustomer = (payload) => ({
    type: CREATE_NEW_CUSTOMER,
    payload
});

export const createNewCustomerSuccess = (payload) => ({
    type: CREATE_NEW_CUSTOMER_SUCCESS,
    payload
});

export const createNewCustomerError = (payload) => ({
    type: CREATE_NEW_CUSTOMER_ERROR,
    payload
});

export const getCustomerList = (search='', callback=null) => ({
    type: GET_CUSTOMER_LIST,
    payload: {search, callback}
});

export const getCustomerListSuccess = (payload) => ({
    type: GET_CUSTOMER_LIST_SUCCESS,
    payload
});

export const getCustomerListError = (payload) => ({
    type: GET_CUSTOMER_LIST_ERROR,
    payload
});

export const getDriverList = (search='', callback=null) => ({
    type: GET_DRIVER_LIST,
    payload: {search, callback}
});

export const getDriverListSuccess = (payload) => ({
    type: GET_DRIVER_LIST_SUCCESS,
    payload
});

export const getDriverListError = (payload) => ({
    type: GET_DRIVER_LIST_ERROR,
    payload
});

export const getFMSVehicleList = (search='', callback=null) => ({
    type: GET_FMS_VEHICLE_LIST,
    payload: {search, callback}
});

export const getFMSVehicleListSuccess = (payload) => ({
    type: GET_FMS_VEHICLE_LIST_SUCCESS,
    payload
});

export const getFMSVehicleListError = (payload) => ({
    type: GET_FMS_VEHICLE_LIST_ERROR,
    payload
});

