import {
  FLEETBASE_LOGIN,
  FLEETBASE_LOGIN_ERROR,
  FLEETBASE_LOGIN_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER
} from '../contants';

export const userDetails = (payload) => ({
  type: LOGIN_USER,
  payload: payload,
});

export const userDetailsSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: {user}
});

export const userDetailsError = (payload) => ({
  type: LOGIN_USER_ERROR,
  payload: payload
})

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const fleetbaseLogin = (payload) => ({
  type: FLEETBASE_LOGIN,
  payload: payload
});

export const fleetbaseLoginSuccess = (payload) => ({
  type: FLEETBASE_LOGIN_SUCCESS,
  payload: payload
});

export const fleetbaseLoginError = (payload) => ({
  type: FLEETBASE_LOGIN_ERROR,
  payload: payload
})