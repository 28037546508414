import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import journeySaga from './journey/saga';
import dashboardSaga from './dashboard/saga';
import calcSaga from './calculator/saga';
import fmsSaga from './fms/saga';
import permissionSaga from './permission/saga';



export default function* rootSaga() {
  yield all([
    authSaga(),
    journeySaga(),
    dashboardSaga(),
    calcSaga(),
    fmsSaga(),
    permissionSaga()
  ]);
}
