import { 
    GET_EV_DETAILS_ON_TYPE,
    GET_EV_DETAILS_ON_TYPE_ERROR,
    GET_EV_DETAILS_ON_TYPE_SUCCESS,
    GET_STATE_LIST, 
    GET_STATE_LIST_ERROR, 
    GET_STATE_LIST_SUCCESS 
} from "redux/contants";


const stateList = {
    loading: false,
    error: null,
    states: []
};

export const stateListReducer =  (state = stateList, action) => {
  switch (action.type) {
    case GET_STATE_LIST:
      return {...state, list: [], error: null, loading: true};
    case GET_STATE_LIST_SUCCESS: 
      return {...state, loading: false, states: action?.payload};
    case GET_STATE_LIST_ERROR: 
      return {...state, loading: false, error: action?.payload}
    default:
      return { ...state };
  }
};

const evDetails = {
    loading: false,
    error: null,
    details: []
}

export const evDetailsReducer = (state=evDetails, action) => {
    switch (action.type) {
        case GET_EV_DETAILS_ON_TYPE:
          return {...state, details: [], error: null, loading: true};
        case GET_EV_DETAILS_ON_TYPE_SUCCESS: 
          return {...state, loading: false, details: action?.payload};
        case GET_EV_DETAILS_ON_TYPE_ERROR: 
          return {...state, loading: false, error: action?.payload}
        default:
          return { ...state };
    }
}