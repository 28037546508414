import {
  JOURNEY_STEPS,
  JOURNEY_BASIC_INFO,
  STEP_FIELDS,
  STEP_INDEX,
  JOURNEY_REQUIREMENTS,
  JOURNEY_BUSINESS_KYC,
  SUBMIT_JOURNEY_FORM,
  SUBMIT_JOURNEY_FORM_SUCCESS,
  SUBMIT_JOURNEY_FORM_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  SUBMIT_PRODUCTS,
  SUBMIT_PRODUCTS_SUCCESS,
  SUBMIT_PRODUCTS_ERROR,
  GET_JOURNEY_DETAILS,
  GET_JOURNEY_DETAILS_SUCCESS,
  GET_JOURNEY_DETAILS_ERROR,
  CAMPAIGN_DATA,
  GET_BRE_RESULT,
  GET_BRE_RESULT_SUCCESS,
  GET_BRE_RESULT_ERROR,
  JOURNEY_BUSINESS_INFO,
  GET_APPLICATION_SUMMARY,
  GET_APPLICATION_SUMMARY_SUCCESS,
  GET_APPLICATION_SUMMARY_ERROR,
  GET_CITY_LIST,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_ERROR,
  GET_BANK_LIST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_ERROR
} from '../contants';

const journeyDetails = {
  isLoading: false,
  error: null,
  journeyData: {}
}

export const journeyDetailsReducer = (state=journeyDetails, action) => {
  switch(action?.type) {
    case GET_JOURNEY_DETAILS:
      return {...state, isLoading: true, error: ''}
    case GET_JOURNEY_DETAILS_SUCCESS:
      return {...state, isLoading: false, error: '', journeyData: action?.payload}
    case GET_JOURNEY_DETAILS_ERROR:
      return {...state, isLoading: false, error: action?.payload}
    default:
      return state;  
  }
}

export const journeyStepReducer = (state=[], action) => {
  switch (action.type) {
    case JOURNEY_STEPS:
      return action?.payload;
    default:
      return [];
  }
}

export const stepFieldsReducer = (state={}, action) => {
  switch(action.type) {
    case STEP_FIELDS:
      return {...action?.payload };
    default:
      return {...state}
  }
}

export const journeyDataReducer = (state={}, action) => {
  switch(action.type) {
    case JOURNEY_BASIC_INFO:
      state.basicInfo = {...state?.basicInfo, ...action?.payload }
      return state;
    case JOURNEY_REQUIREMENTS: 
      state.requirements = {...state.requirements, ...action?.payload}
      return state;
    case JOURNEY_BUSINESS_KYC: 
      state.businessKyc = {...state.businessKyc, ...action?.payload}
      return state;
    case JOURNEY_BUSINESS_INFO:
      state.businessInfo = {...state?.businessInfo, ...action?.payload}
      return state;
    default:
      return {...state};
  }
}

const journeyFormState = {
  isLoading: false,
  error: '',
  data: {}
}

export const journeyFormReducer = (state=journeyFormState, action) => {
  switch(action?.type) {
    case SUBMIT_JOURNEY_FORM:
      return {...state, isLoading: true, error: ''}
    case SUBMIT_JOURNEY_FORM_SUCCESS:
      return {...state, isLoading: false, error: ''}
    case SUBMIT_JOURNEY_FORM_ERROR:
      return {...state, isLoading: false, error: action?.payload}
    default:
      return state;  
  }
}

const product = {
  isLoading: false,
  error: null,
  data: []
}

export const productReducer = (state=product, action) => {
  switch(action?.type) {
    case GET_PRODUCT_LIST:
      return {...state, isLoading: true, error: ''}
    case GET_PRODUCT_LIST_SUCCESS:
      return {...state, isLoading: false, error: '', data: action?.payload}
    case GET_PRODUCT_LIST_ERROR:
      return {...state, isLoading: false, error: action?.payload}
    default:
      return state;  
  }
}

const submitProducts = {
  isLoading: false,
  error: null,
  data: []
}

export const submitProductReducer = (state=submitProducts, action) => {
  switch(action?.type) {
    case SUBMIT_PRODUCTS:
      return {...state, isLoading: true, error: ''}
    case SUBMIT_PRODUCTS_SUCCESS:
      return {...state, isLoading: false, error: '', data: action?.payload}
    case SUBMIT_PRODUCTS_ERROR:
      return {...state, isLoading: false, error: action?.payload}
    default:
      return state;  
  }
}

export const campaignDataReducer = (state={}, action) => {
  switch(action?.type) {
    case CAMPAIGN_DATA:
      return {...action?.payload}
    default:
      return state
  }
};

export const breResultReducer = (state={}, action) => {
  switch(action?.type) {
    case GET_BRE_RESULT:
      return state;
    case GET_BRE_RESULT_SUCCESS:
      return  {...action?.payload};
    case GET_BRE_RESULT_ERROR:
      return state;
    default:
      return state;
  }
};

const applicationSummary = {
  loading: false,
  error: null,
  summary: {}
}

export const applicationSummaryReducer = (state=applicationSummary, action) => {
  switch(action?.type) {
    case GET_APPLICATION_SUMMARY:
      return {...state, loading: true, error: null};
    case GET_APPLICATION_SUMMARY_SUCCESS:
      return {...state, loading: false, summary: action?.payload};
    case GET_APPLICATION_SUMMARY_ERROR:
      return {...state, loading: false, error: action?.payload};
    default:
      return state;
  }
}

const constantData = {
  loading: false,
  error: null,
  cities: [],
  banks: []
}

export const constantDataReducer = (state=[], action) => {
  switch(action?.type) {
    case GET_CITY_LIST:
      return {...state, loading: true, error: null};
    case GET_CITY_LIST_SUCCESS:
      return {...state, loading: false, cities: action?.payload};
    case GET_CITY_LIST_ERROR:
      return {...state, loading: false, error: action?.payload};
    case GET_BANK_LIST:
      return {...state, loading: true, error: null};
    case GET_BANK_LIST_SUCCESS:
      return {...state, loading: false, banks: action?.payload}
    case GET_BANK_LIST_ERROR:
      return {...state, loading: false, error: action?.payload}
    default:
      return state;
  }
}