import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { UserService } from 'service/user';
import {
  FLEETBASE_LOGIN,
  LOGIN_USER,
} from '../contants';
import {
  userDetailsSuccess,
  userDetailsError,
  fleetbaseLoginError,
  fleetbaseLoginSuccess,
} from './actions';
import { Toaster } from 'components/toaster/Toaster';

const server = new UserService();
const toaster = new Toaster();

export function* watchuserDetails() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
};

export function* watchFleetbaseLogin() {
  yield takeEvery(FLEETBASE_LOGIN, loginToFleetbase);
};

const getUserDetailsFromServer = async (payload) => (
  await server.getUserDetails().then((response) => (response)).catch((error) => (error))
);

const getFleetbaseAuthFromServer = async (payload) => (
  await server.fleetbaseLogin(payload).then((response) => (response)).catch((error) => (error))
)



function* loginWithEmailPassword(payload) {
  try {
    const response = yield call(getUserDetailsFromServer, {});
    if (response?.data?.success) {
      yield put(userDetailsSuccess(response?.data?.data));
    } else {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(userDetailsError(error));
    toaster.show(true, error?.message);
  }
};

function* loginToFleetbase({payload}) {
  try {
    const {identity, password, remember, url} = payload;
    const response = yield call(getFleetbaseAuthFromServer, {identity, password, remember});
    if(response?.data?.success){
      yield put(fleetbaseLoginSuccess({token: response?.data?.data?.token}));
      localStorage.setItem('ember_simple_auth-session', JSON.stringify({
        "authenticated": {
          "authenticator": "authenticator:fleetbase",
          token: response?.data?.data?.token
        }
      }));
      window.open(`${url}/fleet-ops`, '_blank');
    } else {
      throw new Error(response?.message);
    }
  } catch(error) {
    yield put(fleetbaseLoginError(error?.message));
    toaster.show(true, error?.message);
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchuserDetails),
    fork(watchFleetbaseLogin)
  ]);
};
