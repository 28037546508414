import { 
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_ERROR,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_ORDER,
    CREATE_NEW_ORDER_ERROR,
    CREATE_NEW_ORDER_SUCCESS,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_ERROR,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_DRIVER_LIST,
    GET_DRIVER_LIST_ERROR,
    GET_DRIVER_LIST_SUCCESS,
    GET_FMS_VEHICLE_LIST,
    GET_FMS_VEHICLE_LIST_ERROR,
    GET_FMS_VEHICLE_LIST_SUCCESS,
    GET_ORDER_DETAILS,
    GET_ORDER_DETAILS_ERROR,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_LIST, 
    GET_ORDER_LIST_ERROR, 
    GET_ORDER_LIST_SUCCESS,
    PICKUP_AND_DROP
} from "redux/contants";

const orderData = {
    loading: false,
    error: null,
    list: [],
    details: {},
    pickupAndDrop: {}
};
  
export const fmsOrderReducer = (state=orderData, action) => {
    switch(action?.type){
        case GET_ORDER_LIST:
            return {...state, loading: true, error: null};
        case GET_ORDER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_ORDER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case GET_ORDER_DETAILS:
            return {...state, loading: true, details: {}, error: null};
        case GET_ORDER_DETAILS_SUCCESS:
            return {...state, loading: false, details: action?.payload};
        case GET_ORDER_DETAILS_ERROR:
            return {...state, loading: false, error: action?.payload};
        case PICKUP_AND_DROP:
            return {...state, pickupAndDrop: action?.payload};
        case CREATE_NEW_ORDER:
            return {...state, error: null,loading: true};
        case CREATE_NEW_ORDER_SUCCESS:
            return {...state, error: null, loading: false};
        case CREATE_NEW_ORDER_ERROR:
            return {...state, error: action?.payload, loading: false}
        default:
            return state;
    }
}


const customerData = {
    loading: false,
    error: null,
    list: []
}

export const fmsCustomerReducer = (state=customerData, action) => {
    switch(action?.type){
        case GET_CUSTOMER_LIST:
            return {...state, loading: true, error: null};
        case GET_CUSTOMER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_CUSTOMER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        case CREATE_NEW_CUSTOMER:
            return {...state, error: null,loading: true};
        case CREATE_NEW_CUSTOMER_SUCCESS:
            return {...state, error: null, loading: false};
        case CREATE_NEW_CUSTOMER_ERROR:
            return {...state, error: action?.payload, loading: false}
        default:
            return state;
    }
}

const vehicleData = {
    loading: false,
    error: null,
    list: []
}


export const fmsVehicleReducer = (state=vehicleData, action) => {
    switch(action?.type){
        case GET_FMS_VEHICLE_LIST:
            return {...state, loading: true, error: null};
        case GET_FMS_VEHICLE_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_FMS_VEHICLE_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

const driverData = {
    loading: false,
    error: null,
    list: []
}

export const fmsDriverReducer = (state=driverData, action) => {
    switch(action?.type){
        case GET_DRIVER_LIST:
            return {...state, loading: true, error: null};
        case GET_DRIVER_LIST_SUCCESS:
            return {...state, loading: false, list: action?.payload};
        case GET_DRIVER_LIST_ERROR:
            return {...state, loading: false, error: action?.payload};
        default:
            return state;
    }
}

