import Swal from "sweetalert2";

const imageViewStyle = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger"
  },
  buttonsStyling: false
})



export class Toaster {

  show(error = true, text) {
    if (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        // title: "Oops...",
        text: text || 'Something went wrong',
        showConfirmButton: true,
        // timer: 1500
      })
    } else {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


  viewImage(img, height=400) {
    imageViewStyle.fire({
      imageUrl: img,
      imageHeight: height,
      imageWidth: 400,
      imageAlt: "Image",
      // background: "transparent"
    });
  }

  description(content) {
    return Swal.fire({
      position: "center",
      icon: "success",
      title: "Congratulations",
      html: content,
      showConfirmButton: true,
      didOpen: () => {
        const copyButton = document.getElementById('copyButton');
        if (copyButton) {
          copyButton.addEventListener('click', () => {
            const idToCopy = "HGhjgghjghjghjghjghj"; // Replace "Your ID to copy" with the actual ID you want to copy
            navigator.clipboard.writeText(idToCopy);
          });
        }
      }
    })
  }

  msg(text) {
    Swal.fire({
      position: "top",
      // icon: "success",
      title: "Thats Great",
      text: text,
      color:'green',
      background: 'lightgreen',
      showConfirmButton: false,
      backdrop: 'transparent',
      timer: 3000,
    })
  }


  helpTab(){
    return Swal.fire({
      title: "Need Help",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      html: `
        Write your query to us, our support team will reach out to you within hour.
      `,
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const githubUrl = `https://api.github.com/users/${login}`;
          // const response = await fetch(githubUrl);
          const response = {ok: true}
          if (response.ok) {
            return this.show(false, 'Thanks, We will reach out to you soon.');
          }
          // return response.json();
        } catch (error) {
          this.show(true, 'There was some issue submitting you query');
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }
  


  
}