import { axiosInstance } from "./base";
import { headers } from "./base";

export class UserService {
  COS_BASE_URL = process.env.REACT_APP_COS_BASE_URL;

  requestOtp = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/auth`, payload, {headers: headers()});
  };

  verifyOtp = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/auth`, payload, {headers: headers()});
  };

  getUserDetails = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/customer-details`, {headers: headers()});
  };

  getApplicationDetails = (applicationId) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/get-application/${applicationId}`, {headers: headers()});
  };

  getOEMMakerList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/asset-oem`, {headers: headers()});
  };

  getOEMDetails = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/asset-oem/specific-details`, payload, {headers: headers()});
  };




  // Calculaotr

  calculateTCO = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-tco`, payload, {headers: headers()});
  };


  calculateHomeCharging = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-home-charging`, payload, {headers: headers()});
  };

  calculatePublicCharging = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-public-charging`, payload, {headers: headers()});
  };

  calculateLease = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-lease`, payload, {headers: headers()});
  };

  calculateJourneyCost = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-journey-cost`, payload, {headers: headers()});
  };

  calculateCO2Emissions = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/calculate-co2-emission`, payload, {headers: headers()});
  };

  fleetbaseLogin = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/fleetbase/auth`, payload, {headers: headers()});
  }

  getUserOtp = (payload) => {
    return axiosInstance.post(`${this.COS_BASE_URL}/auth/user-otp`, payload, {headers: headers()});
  }

  getConsolidatedVehicleData= (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/getAllData`, {headers: headers()});
  }

  getConsolidatedDistanceAndCo2 = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/distance-co2-daily-list/?startDate=${payload?.startDate}&endDate=${payload?.endDate}`)
  }

  getConsolidatedDistanceAndCo2OnDate = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/distance-co2-on-specific-date?startDate=${payload?.startDate}&endDate=${payload?.endDate}`)
  }
 
  getConsolidatedLocation = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/latlong`, {headers: headers()});
  }

  getVehicleList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/imeino`, {headers: headers()});
  }

  getIndividualVehicleData = (payload) => {
    return axiosInstance.get(`${this.COS_BASE_URL}/vehicledata/imeino-details/${payload}`, {headers: headers()});
  }

  getStateList = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/calculator/states`, {headers: headers()});
  }

  getEvDetails = () => {
    return axiosInstance.get(`${this.COS_BASE_URL}/calculator/ev/details`, {headers: headers()});
  }

} 

