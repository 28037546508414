import { completedSteps, stepSequence } from 'constants/defaultValues';
import { generateFieldKeyValuePair, generateRequirementFieldKeyValuePair } from './Utils';

export class JourneySequence {
    computeCurrentJourneySteps(journey) {
        let arr = [];
        let currentStep = '';
        let found = false;
        for (let i = 0; i < stepSequence?.length; i++) {
            const step = stepSequence[i];
            for(let j= 0; j < journey?.length; j++){
                if( step === journey[j].name ) {
                    arr.push({name: step, status: journey[j].status});
                    if(!found && !completedSteps.includes(journey[j]?.status)){
                        found = true;
                        currentStep = step; 
                    }
                }
            }
        }

        return arr;
    }

    async computeJourneyStepFields(journey, step){
        let stepFields = '';
        for(let j= 0; j < journey?.length; j++){
            if(journey[j].name == step){
                const result = journey[j].name == 'requirements' ? generateRequirementFieldKeyValuePair(journey[j].products) : generateFieldKeyValuePair(journey[j]?.products);
                stepFields = result;
                break;
            }
        }
        return stepFields;
    }

}