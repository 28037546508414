import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_APPLICATION_SUMMARY,
  GET_BANK_LIST,
  GET_BRE_RESULT,
  GET_CITY_LIST,
  GET_JOURNEY_DETAILS,
  GET_PRODUCT_LIST,
  SUBMIT_JOURNEY_FORM,
  SUBMIT_PRODUCTS
} from '../contants';
import {
  submitJourneyFormSuccess,
  submitJourneyFormError,
  getProductListError,
  getProductListSuccess,
  submitProductError,
  submitProductsSuccess,
  getJourneyDetailsError,
  updateBasicInfo,
  updateRequirements,
  updateBusinessKyc,
  getJourneyDetailsSuccess,
  updateJourneySteps,
  getBreResultError,
  getBreResultSuccess,
  updateStepFields,
  getApplicationSummaryError,
  getApplicationSummarySuccess,
  getBankListError,
  getCityListError,
  getBankListSuccess,
  getCityListSuccess
} from './actions';
import { Toaster } from 'components/toaster/Toaster';
import { JourneyService } from 'service/journey';
import { userDetails, userDetailsSuccess } from 'redux/actions';
import { JourneySequence } from 'helpers/journeySequence';


const server = new JourneyService();
const toaster = new Toaster();
const journeySequence = new JourneySequence();

function* watchJourneyDetails() {
  yield takeEvery(GET_JOURNEY_DETAILS, getJourneyDetails);
}

function* watchFormSubmit() {
  yield takeEvery(SUBMIT_JOURNEY_FORM, submitForm);
}

function* watchProductList() {
  yield takeEvery(GET_PRODUCT_LIST, getProducts);
}

function* watchProductSelection() {
  yield takeEvery(SUBMIT_PRODUCTS, sendSelectedProduct);
}


function* watchBreResult() {
  yield takeEvery(GET_BRE_RESULT, fetchBreResult)
}

function* watchApplicationSummary() {
  yield takeEvery(GET_APPLICATION_SUMMARY, fetchApplicationSummary);
}

function* watchBankList() {
  yield takeEvery(GET_BANK_LIST, fetchBankList);
}

function* watchCityList() {
  yield takeEvery(GET_CITY_LIST, fetchCityList);
}







const sendFormToServer = async (payload) => (
  await server.updateUserDetails(payload).then((response) => (response)).catch((error) => (error))
);

const getJourneyDetailsFromServer = async (payload) => (
  await server.getUserDetails().then((response) => (response)).catch((error) => (error))
);

const getProductListFromServer = async (payload) => (
  await server.getProductList().then((response) => (response)).catch((error) => (error))
)

const sendSelectedProductToServer = async (payload) => (
  await server.submitProducts(payload).then((response) => (response)).catch((error) => (error))
)


const getBreResultFromServer = async (payload) => (
  await server.getBreResult().then((response) => (response)).catch((error) => (error))
);

const stepFieldCalculation = async (payload) => {
  const { steps, name } = payload;
  const currentStepField = await journeySequence?.computeJourneyStepFields(steps, name);
  return currentStepField;
}

const getApplicationSummaryFromServer = async (payload) => (
  await server.getApplicationSummary().then((response) => (response)).catch((error) => (error))
)

const getBankListFromServer = async (payload) => (
  await server.getBankList(payload).then((response) => (response)).catch((error) => (error))
)

const getCitiesListFromServer = async (payload) => (
  await server.getCityList(payload).then((response) => (response)).catch((error) => (error))
)






function* getJourneyDetails({payload}) {
  try{
    const { history } = payload;
    const response = yield call(getJourneyDetailsFromServer, {});
    if(response?.data?.success){
      // if(response?.data?.data?.journey.length === 0){
      //   history.push('/app/applications/list');
      // }
      yield put(userDetailsSuccess(response?.data?.data));
      yield put(updateBasicInfo(response?.data?.data?.applications[0]?.additionalData || {}));
      const applications = response?.data?.data?.applications;
      let form = {};
      for(let application of applications){
        form = {
          ...form,
          [application?.productCode]: {
            ...application?.additionalData
          }
        }
      }
      yield put(updateRequirements(form));
      yield put(updateBusinessKyc(response?.data?.data?.journeyDetails));
      yield put(getJourneyDetailsSuccess(response?.data?.data));
      const steps = response?.data?.data?.journey;
      const arr = journeySequence.computeCurrentJourneySteps(steps);
      yield put(updateJourneySteps(arr));
      let stepsFields = {};
      for (let step of arr){
        const currentStepField = yield call(stepFieldCalculation, {steps: steps, name: step?.name});
        stepsFields = {...stepsFields, [step?.name]: currentStepField};
      }
      yield put(updateStepFields(stepsFields));
      return response;
    } else {
      throw new Error(response?.message);
    }
  } catch(error){
    yield put(getJourneyDetailsError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* submitForm({payload}) {
  try {
    const { form, handleNext, next, steps, step, history } = payload;
    const response = yield call(sendFormToServer, form);
    if (response?.data?.success) {
      const updatedFields = yield call(getJourneyDetails, {payload: {history: history}});
      if(updatedFields?.data?.success){
        yield put(userDetailsSuccess(updatedFields?.data?.data));
        yield put(submitJourneyFormSuccess(response?.data?.data));
        if(handleNext){
          handleNext(next, steps, step);
        }
        yield call(fetchBreResult, {});
      } else {
        throw new Error(updatedFields?.message);
      }
    } else {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(submitJourneyFormError(error));
    toaster.show(true, error?.message);
  }
}

function* getProducts({payload}) {
  try {
    const response = yield call(getProductListFromServer, {});
    if(response?.data?.success){
      yield put(getProductListSuccess(response?.data?.category));
    } else {
      throw new Error(response?.message);
    }
  } catch(error){
    yield put(getProductListError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* sendSelectedProduct({payload}) {
  try {
    const {products, history, path} = payload;
    yield put(getJourneyDetailsSuccess({}));
    const response = yield call(sendSelectedProductToServer, products);
    if(response?.data?.success){
      yield put(submitProductsSuccess(response?.data?.data));
      history.push(path);
    } else {
      throw new Error(response?.message);
    }
  } catch(error){
    yield put(submitProductError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* fetchBreResult({payload}) {
  try {
    const response = yield call(getBreResultFromServer, {});
    if(response?.data?.success){
      yield put(getBreResultSuccess(response?.data));
    } else {
      throw new Error(response?.message);
    }
  } catch(error){
    yield put(getBreResultError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* fetchApplicationSummary() {
  try {
    const response = yield call(getApplicationSummaryFromServer, {});
    if(response?.data?.success){
      yield put(getApplicationSummarySuccess(response?.data?.data));
    } else {
      throw new Error(response?.message);
    }
  } catch(error) {
    yield put(getApplicationSummaryError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* fetchBankList({payload}) {
  try {
    const response = yield call(getBankListFromServer, payload?.search);
    if(response?.data?.success){
      const data = response?.data?.data?.map((data) => ({...data, label: data?.name, value: data?.name}));
      yield put(getBankListSuccess(data));
      if(payload?.callback){
        payload.callback(data);
      }
    } else {
      throw new Error(response?.message);
    }
  } catch(error) {
    yield put(getBankListError(error?.message));
    toaster.show(true, error?.message);
  }
}

function* fetchCityList({payload}) {
  try {
    const response = yield call(getCitiesListFromServer, payload?.search);
    if(response?.data?.success){
      const data = response?.data?.data?.map((data) => ({...data, label: data?.city, value: data?.city}));
      yield put(getCityListSuccess(data))
      if(payload?.callback){
        payload.callback(data);
      }
    } else {
      throw new Error(response?.message);
    }
  } catch(error) {
    yield put(getCityListError(error?.message));
    toaster.show(true, error?.message);
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchFormSubmit),
    fork(watchProductList),
    fork(watchProductSelection),
    fork(watchJourneyDetails),
    fork(watchBreResult),
    fork(watchApplicationSummary),
    fork(watchBankList),
    fork(watchCityList)
  ]);
}
