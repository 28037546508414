import { GET_PERMISSION_LIST_SUCCESS,GET_PERMISSION_LIST,GET_PERMISSION_LIST_ERROR} from "redux/contants";


export const getPermissionList = (payload) => ({
    type: GET_PERMISSION_LIST,
    payload
});

export const getPermissionListSuccess = (payload) => ({
    type: GET_PERMISSION_LIST_SUCCESS,
    payload
});

export const getPermissionListError = (payload) => ({
    type: GET_PERMISSION_LIST_ERROR,
    payload
});

