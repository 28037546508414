import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_ORDER,
    GET_CUSTOMER_LIST,
    GET_DRIVER_LIST,
    GET_FMS_VEHICLE_LIST,
    GET_ORDER_LIST,
} from '../contants';
import { Toaster } from 'components/toaster/Toaster';
import { FmsService } from 'service/fms';
import { 
    createNewCustomerError,
    createNewCustomerSuccess,
    createNewOrderError,
    createNewOrderSuccess,
    getCustomerListError,
    getCustomerListSuccess,
    getDriverListError,
    getDriverListSuccess,
    getFMSVehicleListError,
    getFMSVehicleListSuccess,
    getOrderListError,
    getOrderListSuccess 
} from './action';

const server = new FmsService();
const toaster = new Toaster();

function* watchGetOrderList() {
  yield takeEvery(GET_ORDER_LIST, fetchOrderList);
}

function* watchCreateOrder() {
    yield takeEvery(CREATE_NEW_ORDER, createNewOrder);
}

function* watchGetCustomerList() {
    yield takeEvery(GET_CUSTOMER_LIST, fetchCustomerList);
}

function* watchCreateCustomer() {
    yield takeEvery(CREATE_NEW_CUSTOMER, createNewCustomer);
}

function* watchDriverList() {
    yield takeEvery(GET_DRIVER_LIST, fetchDriverList);
}

function* watchFmsVehicleList() {
    yield takeEvery(GET_FMS_VEHICLE_LIST, fetchFMSVehicleList);
}





const fetchOrderListFromServer = async (payload) => (
  await server.getOrderList().then((response) => (response)).catch((error) => (error))
);

const createNewOrderToServer = async (payload) => (
    await server.createNewOrder(payload).then((response) => (response)).catch((error) => (error))
);

const fetchCustomerListFromServer = async (payload) => (
    await server.getCustomerList(payload?.search).then((response) => (response)).catch((error) => (error))
);

const createNewCustomerToServer = async (payload) => (
    await server.createNewCustomer(payload).then((response) => (response)).catch((error) => (error))
);

const fetchDriverListFromServer = async (payload) => (
    await server.getDriverList(payload?.phoneNumber, payload?.name, payload?.licenseNumber).then((response) => (response)).catch((error) => (error))
);

const fetchFMSVehicleListFromServer = async (payload) => (
    await server.getVehicleList(payload?.vehicleNumber).then((response) => (response)).catch((error) => (error))
)




function* fetchOrderList({payload}) {
  try {
    const response = yield call(fetchOrderListFromServer, {});
    if(response?.data?.success){
        yield put(getOrderListSuccess(response?.data?.data));
    } else {
        throw new Error(response?.message);
    }
  } catch (error) {
        yield put(getOrderListError(error?.message));
        toaster.show(true, error?.message);
  }
}

function* createNewOrder({payload}) {
    try {
        const response = yield call(createNewOrderToServer, payload);
        if(response?.data?.success){
            yield put(createNewOrderSuccess(response?.data?.data));
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(createNewOrderError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchCustomerList({payload}) {
    try {
        const {search, callback} = payload;
        const response = yield call(fetchCustomerListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((customer) => ({...customer, label: customer?.name, value: customer?.mobileNumber}));
            yield put(getCustomerListSuccess(data));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getCustomerListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* createNewCustomer({payload}) {
    try {
        const response = yield call(createNewCustomerToServer, payload);
        if(response?.data?.success) {
            yield put(createNewCustomerSuccess(response?.data?.data));
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(createNewCustomerError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchDriverList({payload}){
    try {
        const {search, callback} = payload;
        const response = yield call(fetchDriverListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((driver) => ({...driver, label: driver?.firstName, value: driver?.code}))
            yield put(getDriverListSuccess(data));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getDriverListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchFMSVehicleList({payload}) {
    try {
        const {search, callback} = payload;
        const response = yield call(fetchFMSVehicleListFromServer, search);
        if(response?.data?.success){
            const data = response?.data?.data?.map((vehicle) => ({...vehicle, label: vehicle?.vehicleNumber, value: vehicle?.code}))
            yield put(getFMSVehicleListSuccess(data));
            if(callback){
                callback(data)
            }
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(getFMSVehicleListError(error?.message));
        toaster.show(true, error?.message);
    }
} 




export default function* fmsSaga() {
  yield all([
    fork(watchGetOrderList),
    fork(watchCreateOrder),
    fork(watchGetCustomerList),
    fork(watchCreateCustomer),
    fork(watchDriverList),
    fork(watchFmsVehicleList)
  ]);
}
