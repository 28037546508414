import { GET_PERMISSION_LIST_SUCCESS,GET_PERMISSION_LIST,GET_PERMISSION_LIST_ERROR} from "redux/contants";

const permissionList = {
    loading: false,
    error: null,
    permissions: []
};


export const permissionListReducer =  (state = permissionList, action) => {
    switch (action.type) {
      case GET_PERMISSION_LIST:
        return {...state, list: [], error: null, loading: true};
      case GET_PERMISSION_LIST_SUCCESS: 
        return {...state, loading: false, permissions: action?.payload};
      case GET_PERMISSION_LIST_ERROR: 
        return {...state, loading: false, error: action?.payload}
      default:
        return { ...state };
    }
  };