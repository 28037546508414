import { 
  GET_CONSOLIDATED_DISTANCE_AND_CO2,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS,
  GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS,
  GET_CONSOLIDATED_LOCATION,
  GET_CONSOLIDATED_LOCATION_ERROR,
  GET_CONSOLIDATED_LOCATION_SUCCESS,
  GET_CONSOLIDATED_VEHICLE_DATA,
  GET_CONSOLIDATED_VEHICLE_DATA_ERROR,
  GET_CONSOLIDATED_VEHICLE_DATA_SUCCESS,
  GET_GLOBAL_EV_DATA,
  GET_GLOBAL_EV_DATA_ERROR,
  GET_GLOBAL_EV_DATA_SUCCESS,
  GET_INDIVIDUAL_VEHICLE_DATA,
  GET_INDIVIDUAL_VEHICLE_DATA_ERROR,
  GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS,
  GET_SPECIFICDATA_ON_KEY,
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_ERROR,
  GET_VEHICLE_LIST_SUCCESS,
  MAKER_LIST, 
  MODEL_LIST, 
  VARIENT_LIST, 
  VEHICLE_DETAILS, 
  VEHICLE_LIST 
} from "redux/contants";

const INIT_STATE = { maker: [], model: [], varient: []}

export const oemData =  (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAKER_LIST:
      const makerList = action?.payload?.map((item) => ({...item, label: item?.name, value: item?.code}))
      return {maker: makerList, model: [], varient: []};
    case MODEL_LIST:
      let modelList = action?.payload?.map((item) => ({...item, label: item?.modelName, value: item?.modelName}))
      modelList = [...modelList, {label: 'N/A', value: 'N/A'}]
      return {...state, model: modelList, varient: []};
    case VARIENT_LIST:
      let varientList = action?.payload.map((item) => ({...item, label: item?.variantName, value: item?.variantName}));
      varientList = [...varientList, {label: 'N/A', value: 'N/A'}]
      return { ...state, varient: varientList};
    default:
      return { ...state };
  }
};

export const vehicleDetails = (state = {}, action) => {
  switch(action?.type){
    case VEHICLE_DETAILS:
      return {...action?.payload}
    default:
      return state
  }
}

export const vehicleList = (state = [], action) => {
  switch(action?.type){
    case VEHICLE_LIST:
      return [...state, action?.payload]
    default:
      return state
  }
}

const globalEVData = {
  loading: false,
  error: null,
  details: {}
};

export const globalEVDataReducer = (state=globalEVData, action) => {
  switch(action?.type){
    case GET_GLOBAL_EV_DATA:
      return {...state, loading: true, error: null};
    case GET_GLOBAL_EV_DATA_SUCCESS:
      return {...state, loading: false, details: action?.payload};
    case GET_GLOBAL_EV_DATA_ERROR:
      return {...state, loading: false, error: action?.payload};
    default:
      return state
  }
}

const initialPartnerVehicleData = {
  loading: false,
  error: null,
  consolidated: {
    vehicleStatus: [10, 20, 30],
    temperatureStatus: [10, 20, 30, 40],
    batteryStatus: [10, 20, 30, 40],
    trackingStatus: '-/-'
  },
  individual: {},
  vehicleList: [],
  locationList: []
};

export const partnerVehicleDataReducer = (state=initialPartnerVehicleData, action) => {
  switch(action?.type){
    case GET_CONSOLIDATED_VEHICLE_DATA:
      return {...state, loading: state?.vehicleList?.length === 0, error: null};
    case GET_CONSOLIDATED_VEHICLE_DATA_SUCCESS:
      console.log(action?.payload, 'in reducer')
      return {...state, loading: false, consolidated: action?.payload, vehicleList: action?.payload?.data};
    case GET_CONSOLIDATED_VEHICLE_DATA_ERROR:
      return {...state, loading: false, error: action?.payload || 'Something went wrong'};
    case GET_VEHICLE_LIST:
      return {...state, loading: true, error: null};
    case GET_VEHICLE_LIST_SUCCESS:
      return {...state, loading: false, error: null, vehicleList: action?.payload};
    case GET_VEHICLE_LIST_ERROR:
      return {...state, loading: false, error: action?.payload};
    case GET_CONSOLIDATED_LOCATION: 
      return {...state, loading: true, error: null, locationList: []}
    case GET_CONSOLIDATED_LOCATION_SUCCESS:
      return {...state, loading: false, locationList: action?.payload}
    case GET_CONSOLIDATED_LOCATION_ERROR:
      return {...state, loading: false, error: action?.payload || 'Something went wrong'}
    case GET_INDIVIDUAL_VEHICLE_DATA:
      return {...state, loading: true, error: null};
    case GET_INDIVIDUAL_VEHICLE_DATA_SUCCESS: 
      return {...state, loading: false, individual: action?.payload};
    case GET_INDIVIDUAL_VEHICLE_DATA_ERROR:
      console.log(action, 'tetsing')
      return {...state, loading: false, error: action?.payload || 'Something went wrong'}
    default:
      return state;
  }
}

const consolidatedDistanceAndCo2 = {
  loading: {
    co2: false,
    distance: false
  },
  error: null,
  overall: {
    data: [],
    distance: [100, 200, 300, 200, 250],
    co2: [120, 300, 210, 310, 400]
  },
  onDate: []
}

export const partnerVehicleDistanceAndCo2Reducer = (state=consolidatedDistanceAndCo2, action) => {
  switch(action?.type){
    case GET_CONSOLIDATED_DISTANCE_AND_CO2:
      let loader = {};
      if(action?.payload?.key){
        loader = {...state?.loading, [action?.payload?.key]: true}
      } else {
        loader = {co2: true, distance: true}
      }
      return {...state, loading: loader, error: null};
    case GET_CONSOLIDATED_DISTANCE_AND_CO2_SUCCESS:
      let ovr = {...state?.overall, data: action?.payload?.data};
      if(action?.payload?.key === 'distance'){
        ovr.distance = action?.payload?.distance;
      }else if(action?.payload?.key === 'co2'){
        ovr.co2 = action?.payload?.co2;
      } else {
        ovr.distance = action?.payload?.distance;
        ovr.co2 = action?.payload?.co2;
      }
      return {...state, loading: {co2: false, distance: false}, overall: ovr};
    case GET_CONSOLIDATED_DISTANCE_AND_CO2_ERROR:
      return {...state, loading: {co2: false, distance: false}, error: action?.payload};
    case GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE:
      return {...state, loading: {...state, [action?.payload?.type]: true}, error: null};
    case GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_SUCCESS:
      return {...state, loading: {co2: false, distance: false}, onDate: action?.payload};
    case GET_CONSOLIDATED_DISTANCE_AND_CO2_ON_SPECIFIC_DATE_ERROR:
      return {...state, loading: {co2: false, distance: false}, error: action?.payload};
    default:
      return state;
  }
}