import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  productImage,
} from "constants/defaultValues";
import { fieldConstants } from "constants/journeyConstants";

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  try {
    localStorage.setItem("direction", direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = "rounded";
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
      error
    );
    currentRadius = "rounded";
  }
  return currentRadius;
};

export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem("currentLanguage") &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem("currentLanguage")
      ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    );
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem("currentLanguage", locale);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    );
  }
};

export const getToken = () => {
  let user = null;
  try {
    user = localStorage.getItem("token");
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getToken -> error", error);
    user = null;
  }
  return user;
};

export const setToken = (token) => {
  try {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setToken -> error", error);
  }
};

// journey helpers

export const generateFieldKeyValuePair = (products) => {
  let result = {};
  products.forEach((product) => {
    product?.fields?.forEach((field) => {
      result = {
        ...result,
        [field.code]: {
          name: field.name,
          isMendatory: field.isMendatory,
          type: field.type,
          defaultValue: field.defaultValue,
          isActive: field.isActive,
          status: field.status,
          rekycReason: field.rekycReason
        },
      };
    });
  });

  return result;
};

export const generateRequirementFieldKeyValuePair = (products) => {
  let result = {};
  products?.forEach((product) => {
    product?.fields?.forEach((field) => {
      result = {
        ...result,
        [product.productCode]: {
          ...result[product.productCode],
          [field.code]: {
            productName: product.productName,
            productCode: product.productCode,
            category: product.category,
            subCategory: product.subCategory,
            name: field.name,
            isMendatory: field.isMendatory,
            type: field.type,
            defaultValue: field.defaultValue,
            isActive: field.isActive.Date,
            status: field?.status,
            description: field?.description,
            rekycReason: field?.rekycReason
          },
        },
      };
    });
  });

  return result;
};

export const validateFormAndCreatePayload = (form, stepFields, journeyData) => {
  let payload = {};
  const result = Object.keys(stepFields).every((key) => {
    // if(form?.[key] !== journeyData?.[key]){
      if((key === fieldConstants?.businessCategory?.code || key === fieldConstants?.topDebtors?.code)) {
        let str = '';
        form?.[key]?.forEach((obj, index) => {
          if(index === form?.[key]?.length - 1){
            str = str + `${obj?.value}`;
          } else {
            str = str + `${obj?.value}, `;
          }
        })
        payload = {...payload, [key]: str};
      } else {
        payload = {...payload, [key]: form[key]};
      }
    // }
    return (
      !stepFields?.[key]?.isMendatory ||
      (form.hasOwnProperty(key) && form[key] !== "")
    );
  });

  return { result, payload };
};

export const validateFormAndCreateRequirementsPayload = (
  form,
  stepFields,
  journeyData
) => {
  let payload = [];
  let result = true;

  for (let productCode in stepFields) {
    let obj = {};
    for (let key in stepFields[productCode]) {
      if (stepFields?.[productCode] && form?.[productCode]) {
        if (journeyData?.[productCode] && journeyData?.[productCode]?.[key]) {
          // if(form?.[productCode]?.[key] != journeyData?.[productCode]?.[key]){
          obj = { ...obj, [key]: form[productCode][key] };
          // }
        } else {
          obj = { ...obj, [key]: form[productCode][key] };
        }
      }
      if (
        (!form?.[productCode] ||
          !form?.[productCode]?.[key] ||
          form?.[productCode]?.[key] == "") &&
        stepFields?.[productCode]?.[key]?.isMendatory
      ) {
        result = false;
        break;
      }
    }
    payload = [...payload, { ...obj, productCode }];
    if (!result) {
      break;
    }
  }

  return { result, payload };
};

export const validateDocuments = (form, stepFields) => {
  const result = Object.keys(stepFields).every((key) => {
    return (
      !stepFields?.[key]?.isMendatory ||
      (form.hasOwnProperty(key) && form[key] !== "")
    );
  });
  return result;
};

export const validateMandeoryFields = (form, stepFields) => {
  const result = stepFields.every((field) => {
    return form.hasOwnProperty(field) && form[field] !== ""
  });

  return result;
};

export const validateRevenueBreakup = (revenue, form) => {
  let result = false;
  revenue?.forEach((partner) => {
    if (
      partner?.customer ||
      partner?.typeOfVehicleDeployed ||
      partner?.manufacturer ||
      partner?.numberOfVehicleDeployed ||
      form?.revenueBreakup?.[partner?.name]
    ) {
      result = true;
    }
  });
  return result;
};

export const validatePan = (pan) => {
  const pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return pattern.test(pan);
};

export const validateGstin = (gstin) => {
  const pattern = /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z]{1})$/;
  return pattern.test(gstin);
};

export const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

export const validateCin = (cin) => {
  const pattern =
    /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  return pattern.test(cin);
};

export const validateUdyam = (udyam) => {
  const pattern = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/;
  return pattern.test(udyam);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date?.getDate()).padStart(2, "0");
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const year = date?.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDate2 = (dateString) => {
  const date = new Date(dateString);
  const day = String(date?.getDate()).padStart(2, "0");
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const year = date?.getFullYear();
  if(!year || !month || !day){
    return 'YYYY-MM-DD'
  }
  return `${year}-${month}-${day}`;
};

export const recreateDate = (dateString) => {
  if (dateString) {
    const [day, month, year] = dateString?.split("/").map(Number);
    return new Date(year, month - 1, day); // month - 1 because months are zero-based
  }
  return dateString;
};

export const productLogo = (productName) => {
  if (productImage[productName]) {
    return productImage[productName];
  }

  return productImage.default;
};

export const validateForm = (form) => {
  const result = Object.keys(form || {})?.every((key) => {
    return form?.hasOwnProperty(key) && form?.[key];
  });
  return result;
};
